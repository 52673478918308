import { configureStore } from "@reduxjs/toolkit";
import webChatSlice from "../slices/webChatSlice";
import toastSlice from "../slices/ToastSlice";

const store = configureStore({
  reducer: {
    webChat: webChatSlice.reducer,
    toast: toastSlice.reducer,
  },
});

export default store;
