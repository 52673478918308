import React, { useState, useEffect } from "react";

const Toast = ({ message, showToast, setShowToast, time = 3000 }) => {
  useEffect(() => {
    let timeoutId;

    if (showToast) {
      timeoutId = setTimeout(() => {
        setShowToast(false);
      }, time);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [showToast, time]);

  const handleClick = () => {
    setShowToast(true);
  };

  return (
    <div>
      <style>
        {`
          #snackbar {
            visibility: hidden;
            min-width: 250px;
            margin-left: -125px;
            background-color: #333;
            color: #fff;
            text-align: center;
            border-radius: 2px;
            padding: 16px;
            position: fixed;
            z-index: 1;
            left: 50%;
            bottom: 30px;
            font-size: 17px;
          }

          #snackbar.show {
            visibility: visible;
            -webkit-animation: fadein 0.5s, fadeout 0.5s ${time / 1000}s;
            animation: fadein 0.5s, fadeout 0.5s ${time / 1000}s;
          }

          @-webkit-keyframes fadein {
            from { bottom: 0; opacity: 0; } 
            to { bottom: 30px; opacity: 1; }
          }

          @keyframes fadein {
            from { bottom: 0; opacity: 0; }
            to { bottom: 30px; opacity: 1; }
          }

          @-webkit-keyframes fadeout {
            from { bottom: 30px; opacity: 1; } 
            to { bottom: 0; opacity: 0; }
          }

          @keyframes fadeout {
            from { bottom: 30px; opacity: 1; }
            to { bottom: 0; opacity: 0; }
          }
        `}
      </style>
      <div id="snackbar" className={showToast ? "show" : ""}>
        {message}
      </div>
    </div>
  );
};

export default Toast;
