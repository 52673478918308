import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import { saveCustomerFeedback, updateChat } from "../slices/api/webChatAPI";
import Constants from "../constants/Constants";
import PendingIcon from "@mui/icons-material/Pending";
import axios from "axios";
// import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import {
  setAiConversation,
  setAppendedComps,
  setIsWaitingForAiResponse,
  setOpenChatData,
  setShowMainOptions,
  setTypeOfChatOpen,
} from "../slices/webChatSlice";
import {
  setShowToast,
  setToastMessage,
  setToastTimer,
} from "../slices/ToastSlice";
import StorageService from "../services/storageService";

const WriteMessage = () => {
  const storageService = new StorageService();
  const dispatch = useDispatch();
  const { aiConversation } = useSelector((state) => state.webChat);
  const [aiMessage, setAiMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [message, setMessage] = useState("");
  const { openChatId, typeOfChatOpen, appendedComps, openChatData } =
    useSelector((state) => state.webChat);

  const [files, setFiles] = useState([]);
  const allowedTypes = [
    "image/png",
    "image/jpeg",
    "image/gif",
    "image/webp",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/plain",
    "video/mp4",
    "video/mpeg",
  ];

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const validFiles = selectedFiles.filter((file) =>
      allowedTypes.includes(file.type)
    );

    if (validFiles.length !== selectedFiles.length) {
      alert(
        "Some of the selected files are not supported and have been excluded from the upload."
      );
    }

    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const handleChange = (e) => setMessage(e.target.value);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!message.trim()) return;

    setIsSubmitting(true);

    if (typeOfChatOpen === Constants.openChatTypes.FEEDBACK) {
      await saveCustomerFeedback({
        feedbackContent: message,
        sender: "webChat",
        messageType: Constants.allowedIncomingMessages.TYPES.TEXT,
        dateCreated: new Date().toString(),
        isRead: false,
      });

      const text = message;
      const thankYouForFeeBackMessage = (
        <div className="chat-message-container message">
          <div className="chat-message outgoing-message">
            <p>
              <b>Your Submitted Feedback: </b> {text}
            </p>
            <p
              dangerouslySetInnerHTML={{
                __html: Constants.messages.FEEDBACK_THANK_YOU,
              }}
            ></p>
          </div>
        </div>
      );

      const newArr = [...appendedComps];
      newArr.splice(-1, 1);
      const updatedComps = [thankYouForFeeBackMessage];

      dispatch(setAppendedComps(updatedComps));
      dispatch(setTypeOfChatOpen(null));
      dispatch(setOpenChatData(null));
      setMessage("");
    } else {
      const messageObject = {
        message: {
          messageType: Constants.allowedIncomingMessages.TYPES.TEXT,
          messageContent: message,
          sender: Constants.messageSender.CLIENT,
          dateCreated: new Date().toString(),
          isRead: false,
        },
        dateUpdated: new Date().toString(),
      };

      const chatData = {
        chatId: openChatId,
        messageObject,
      };

      try {
        const uploadFiles = async () => {
          try {
            const uploadPromises = files.map(async (file) => {
              try {
                const uploadFileResult = await storageService.uploadFile(
                  file,
                  Constants.typeOfService.CHATS
                );
                if (!messageObject.message.attachmentIds) {
                  messageObject.message.attachmentIds = [];
                }
                messageObject.message.attachmentIds.push(uploadFileResult);
              } catch (error) {
                console.log(error);
                throw new Error(
                  "An error occurred while uploading attachment."
                );
              }
            });

            await Promise.all(uploadPromises); // Wait for all uploadPromises to resolve
          } catch (error) {
            dispatch(
              setToastMessage("An error occurred while uploading attachment.")
            );
            dispatch(setToastTimer(3000));
            dispatch(setShowToast(true));
            console.log(error);
            setIsSubmitting(false);
            return false;
          }
        };

        await uploadFiles();
        await updateChat(chatData);
        setFiles([]);
        setMessage("");
        setIsSubmitting(false);
      } catch (error) {
        console.error("Error occurred:", error);
      }
    }

    setIsSubmitting(false);
  };

  const handleSubmitAiMessage = async (e) => {
    e.preventDefault();

    if (!aiMessage.trim()) {
      return;
    } else {
      const simpleGreetings = Constants.normalGreetings;

      const updatedComps = [];
      dispatch(setAppendedComps(updatedComps));
      dispatch(setShowMainOptions(false));

      if (
        simpleGreetings.some((greeting) =>
          aiMessage
            .trim()
            .toLowerCase()
            .replace(/[!.,?]/g, "")
            .includes(greeting)
        ) &&
        aiMessage.trim().length < 15
      ) {
        const preDefinedResponse = Constants.messages.BOT_GREETING;

        const updatedConversation = [
          ...aiConversation,
          { sender: Constants.messageSender.CLIENT, message: aiMessage },
          {
            sender: Constants.messageSender.AGENT,
            message: preDefinedResponse,
          },
        ];

        dispatch(setAiConversation(updatedConversation));
        setAiMessage("");
        return;
      } else {
        dispatch(setIsWaitingForAiResponse(true));
        let data = JSON.stringify({
          question: aiMessage,
        });

        let config = {
          method: "post",
          url: "https://gmf3ckk3mybjr42f43knikexai0djqls.lambda-url.af-south-1.on.aws/",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        const updateAiConversation = [
          ...aiConversation,
          { sender: Constants.messageSender.CLIENT, message: aiMessage },
        ];
        dispatch(setAiConversation(updateAiConversation));

        setAiMessage("");

        axios
          .request(config)
          .then((response) => {
            // console.log(JSON.stringify(response.data));

            const agentMessage =
              response.data.answer &&
              response.data.answer.includes("I don't know") &&
              response.data.answer.length < 15
                ? "I'm sorry, I don't have the answer to that question yet. 🙁 Please try asking something else, or provide more details so I can assist better. You can also connect with a customer agent for support by clicking the button below, or click <b>home</b> at the top and choose <b>Support</b>. 😊 We're constantly learning and improving! 📚✨"
                : response.data.answer;

            const updatedConversation = [
              ...updateAiConversation,
              {
                sender: Constants.messageSender.AGENT,
                message: agentMessage,
              },
            ];

            dispatch(setAiConversation(updatedConversation));
            dispatch(setIsWaitingForAiResponse(false));
          })
          .catch((error) => {
            console.log(error);
            dispatch(setIsWaitingForAiResponse(false));
          });
      }
    }
  };

  useEffect(() => {
    if (openChatData) {
      // console.log(openChatData);
    }
  }, [openChatData]);

  useEffect(() => {}, []);

  if (
    // 1 == 2
    !openChatData ||
    openChatData?.status !== Constants.chatStages.CHAT_OPENED
  )
    return (
      <>
        <div className="write-message-container">
          <div className="attachments"></div>
          <div className="type-message">
            <textarea
              name="web_message"
              id="web_message"
              placeholder="Start chatting with MyWSU Smart Assistant..."
              value={aiMessage}
              onChange={(e) => setAiMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleSubmitAiMessage(e);
                }
              }}
            ></textarea>

            <div className="type-message-btns">
              <button type="button"></button>
              <button
                type="button"
                className="send-btn"
                onClick={handleSubmitAiMessage}
                disabled={isSubmitting}
              >
                {isSubmitting ? <PendingIcon /> : <SendIcon />}
              </button>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-center">
          <p className="m-0 my-company">
            Powered by{" "}
            <a
              href="https://www.myplusplus.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              mY++
            </a>
          </p>
        </div>
      </>
    );

  const shortenFileName = (fileName) => {
    if (fileName.length > 8) {
      const firstFour = fileName.slice(0, 4);
      const lastFour = fileName.slice(-4);
      return `${firstFour}...${lastFour}`;
    }
    return fileName;
  };

  return (
    <div className="write-message-container">
      <div className="attachments">
        {files.map((file, index) => (
          <div key={index}>
            <a
              href={URL.createObjectURL(file)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "15px",
                  padding: "3px 8px",
                  margin: "3px",
                  fontSize: "14px",
                  color: "#555",
                }}
              >
                {shortenFileName(file.name)}
              </span>
            </a>
          </div>
        ))}
      </div>
      <div className="type-message">
        <textarea
          name="web_message"
          id="web_message"
          placeholder={`${
            typeOfChatOpen === Constants.openChatTypes.FEEDBACK
              ? "Enter anonymous feedback here. Include your name/contact if desired..."
              : "Type message..."
          }`}
          value={message}
          onChange={handleChange}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSubmit(e);
            }
          }}
        ></textarea>

        <div className="type-message-btns">
          <button
            type="button"
            onClick={() =>
              document.getElementById("web-chat-attachments-btn").click()
            }
            disabled={isSubmitting}
          >
            <AttachFileIcon />
          </button>
          <input
            type="file"
            name="attachments"
            id="web-chat-attachments-btn"
            hidden
            multiple
            onChange={handleFileChange}
            disabled={isSubmitting}
          />
          <button
            type="button"
            className="send-btn"
            onClick={handleSubmit}
            disabled={isSubmitting || !message?.trim()}
          >
            {isSubmitting ? <PendingIcon /> : <SendIcon />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default WriteMessage;
