import { useSelector, useDispatch } from "react-redux";
import Constants from "../constants/Constants";
import { useEffect, useState } from "react";
import { setAiConversation } from "../slices/webChatSlice";
import BotImage from "./BotImage";

const AiConversation = () => {
  const { aiConversation } = useSelector((state) => state.webChat);
  const dispatch = useDispatch();
  const [lastGeneratedMessage, setLastGeneratedMessage] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [lastAgentMessageIndex, setLastAgentMessageIndex] = useState(null);

  const typeWriter = (message) => {
    let i = 0;
    let currentText = "";
    const speed = 10;

    const typeNextCharacter = () => {
      if (i < message.length) {
        currentText += message.charAt(i);
        setLastGeneratedMessage(currentText);
        i++;
        setTimeout(typeNextCharacter, speed);
      } else {
        setIsTyping(false);
      }
    };

    typeNextCharacter();
  };

  useEffect(() => {
    const lastAgentMessage = aiConversation
      .slice()
      .reverse()
      .find((message) => message.sender === Constants.messageSender.AGENT);

    if (
      lastAgentMessage &&
      lastAgentMessageIndex !== aiConversation.length - 1
    ) {
      setLastAgentMessageIndex(aiConversation.length - 1);
      setIsTyping(true);
      typeWriter(lastAgentMessage.message);
    }
  }, [aiConversation, lastAgentMessageIndex]);

  useEffect(() => {
    return () => {
      dispatch(setAiConversation([]));
    };
  }, [dispatch]);

  if (!aiConversation) return null;

  return (
    <>
      {aiConversation.map((conversation, index) => {
        const isLastAgentMessage =
          conversation.sender === Constants.messageSender.AGENT &&
          index === aiConversation.length - 1;

        return (
          <div
            key={index}
            className={`chat-message-container ${
              conversation.sender === Constants.messageSender.CLIENT
                ? "outgoing-message"
                : ""
            }`}
          >
            <div className="chat-message">
              {conversation.sender === Constants.messageSender.AGENT && (
                <BotImage />
              )}

              {isLastAgentMessage && isTyping ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: lastGeneratedMessage,
                  }}
                />
              ) : (
                <span
                  dangerouslySetInnerHTML={{
                    __html: conversation.message,
                  }}
                />
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default AiConversation;
