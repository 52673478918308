import React from "react";
import CloseIcon from "@mui/icons-material/Close";
// import CachedIcon from "@mui/icons-material/Cached";
import HomeIcon from "@mui/icons-material/Home";
import { useDispatch, useSelector } from "react-redux";
import {
  setAppendedComps,
  setOpenChatId,
  setOpenChatData,
  setShowMainOptions,
  setTypeOfChatOpen,
  setAiConversation,
  setIsWaitingForAiResponse,
} from "../slices/webChatSlice";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import { updateChat } from "../slices/api/webChatAPI";
import Constants from "../constants/Constants";
import WSULogo from "../assets/wsu-logo.png";
// import MyWSULogo from "../assets/white_logo.png";

const Header = ({ toggleChat }) => {
  const dispatch = useDispatch();
  const { openChatId, typeOfChatOpen } = useSelector((state) => state.webChat);

  const handleRefresh = () => {
    let answer = false;
    if (
      openChatId != null &&
      typeOfChatOpen === Constants.openChatTypes.SUPPORT_CONVERSATION
    ) {
      answer = window.confirm(
        "By going to HOME you will also close this chat, are you sure you want to go to HOME? If you meant minimize click button next to close."
      );

      if (answer === true) {
        dispatch(setOpenChatId(null));
        dispatch(setOpenChatData(null));
        dispatch(setAppendedComps([]));
        dispatch(setShowMainOptions(true));
        dispatch(setTypeOfChatOpen(null));

        dispatch(setAiConversation([]));
        dispatch(setIsWaitingForAiResponse(false));
      }
    } else {
      dispatch(setOpenChatId(null));
      dispatch(setOpenChatData(null));
      dispatch(setAppendedComps([]));
      dispatch(setShowMainOptions(true));
      dispatch(setTypeOfChatOpen(null));

      dispatch(setAiConversation([]));
      dispatch(setIsWaitingForAiResponse(false));
    }
  };

  const closeChatAndWindow = () => {
    dispatch(setOpenChatId(null));
    dispatch(setOpenChatData(null));
    dispatch(setAppendedComps([]));
    dispatch(setShowMainOptions(true));
    dispatch(setTypeOfChatOpen(null));

    dispatch(setAiConversation([]));
    dispatch(setIsWaitingForAiResponse(false));

    toggleChat();
  };

  const handleCloseChat = async () => {
    let answer = false;
    if (openChatId != null) {
      answer = window.confirm(
        "Are you sure you want to close this chat? If you meant minimize click button next to close."
      );
    } else {
      answer = true;
    }

    if (answer === true) {
      if (openChatId != null) {
        const chatData = {
          chatId: openChatId,
          dateUpdated: new Date(),
          status: Constants.chatStages.CLOSED,
          messageObject: {
            message: {
              messageType: Constants.allowedIncomingMessages.TYPES.TEXT,
              messageContent: "Chat has been closed by client.",
              sender: Constants.messageSender.CLIENT,
              dateCreated: new Date().toString(),
              isRead: false,
            },
          },
        };
        try {
          await updateChat(chatData);
          closeChatAndWindow();
        } catch (error) {
          console.error("Error occurred:", error);
        }
      } else {
        closeChatAndWindow();
      }
    }
  };

  return (
    <div className="header-part">
      <div className="logo">
        <img src={WSULogo} alt="WSU Logo" />
        <h4 style={{ color: "#FFFFFF" }}>MyWSU</h4>
      </div>
      <div className="action-btns">
        <button type="button">
          <HomeIcon onClick={handleRefresh} />
        </button>
        <button type="button">
          <CloseFullscreenIcon onClick={toggleChat} />
        </button>
        <button type="button" onClick={handleCloseChat}>
          <CloseIcon />
        </button>
      </div>
    </div>
  );
};

export default Header;
