import React, { useState } from "react";
import ForumIcon from "@mui/icons-material/Forum";
import CloseIcon from "@mui/icons-material/Close";
import Header from "./Header";
import Greeting from "./Greeting";
import MainOptions from "./MainOptions";
import WriteMessage from "./WriteMessage";
import OpenChat from "./OpenChat";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import AiConversation from "./AiConversation";
import Toast from "./common/Toast";

const WebChat = () => {
  const toast = useSelector((state) => state.toast);
  const [isChatOpen, setChatOpen] = useState(false);
  const { appendedComps, isWaitingForAiResponse } = useSelector(
    (state) => state.webChat
  );

  const messageAlertStatus = () => {
    if (localStorage.getItem("alert-message") === null) {
      return true;
    } else {
      return localStorage.getItem("alert-message");
    }
  };

  const [messageAlert, setMessageAlert] = useState(messageAlertStatus);

  const toggleChat = () => {
    setChatOpen(!isChatOpen);
    setMessageAlert(false);
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const hideMessageAlert = () => {
    setMessageAlert(false);
    localStorage.setItem("alert-message", false);
  };

  return (
    <div id="mypp_web_chat">
      {isChatOpen && (
        <div className={`web-chat-container slide-up`}>
          <Header toggleChat={toggleChat} />
          <div className="chat-messages-container">
            <Greeting />
            <MainOptions />
            <div>{appendedComps}</div>
            <AiConversation />
            {isWaitingForAiResponse && (
              <div className="chat-loader">
                <span className="jumping-dots">
                  <span className="dot-1"></span>
                  <span className="dot-2"></span>
                  <span className="dot-3"></span>
                </span>
              </div>
            )}
            <OpenChat />
          </div>
          <WriteMessage />
        </div>
      )}

      {!isChatOpen && messageAlert && (
        <div className="bot-message-alert shadow">
          <div className="message">
            <p>
              Welcome to Walter Sisulu University's <b>Smart Assistant!</b>🤖 I
              can answer questions you have with WSU Registra/Admin or connect
              you with our Customer Support Agents!
            </p>
            <button
              type="button"
              className="close-btn"
              onClick={hideMessageAlert}
            >
              <CloseIcon fontSize="xsmall" />
            </button>
          </div>
        </div>
      )}

      {!isChatOpen && (
        <button className="open-chat-btn" onClick={toggleChat}>
          <ForumIcon />
          {messageAlert && <span className="number">1</span>}
        </button>
      )}
      {toast && toast.showToast && toast && toast.toastMessage != "" ? (
        <Toast />
      ) : (
        ""
      )}
    </div>
  );
};

export default WebChat;
