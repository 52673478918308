const CreateGreetingMessage = () => {
  const currentHour = new Date().getHours();
  const greeting =
    currentHour < 12
      ? "Good morning!"
      : currentHour < 18
      ? "Good day!"
      : "Good evening!";

  return (
    <div>
      <p>
        <b>{greeting}</b>
      </p>
      <p>
        Welcome to Walter Sisulu University's <b>Smart Assistant!</b>🤖 Select an option below or start chatting with me to explore everything I can help you with!
      </p>
    </div>
  );
};

const Greeting = () => {
  return (
    <div className="chat-message-container">
      <div className="chat-message">
        <CreateGreetingMessage />
      </div>
    </div>
  );
};

export default Greeting;
