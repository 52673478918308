import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { subscribeToChatAsync } from "../slices/webChatSlice";
import Constants from "../constants/Constants";

const OpenChat = () => {
  const webChat = useSelector((state) => state.webChat);
  const dispatch = useDispatch();
  const { openChatId, openChatData } = webChat;

  const parseWhatsAppMessage = (message) => {
    if (message) {
      message = message.replace(/\n/g, "<br>");
      message = message.replace(/\*([^*]+)\*/g, "<b>$1</b>");
      message = message.replace(/_([^_]+)_/g, "<i>$1</i>");

      return message;
    }

    return null;
  };

  useEffect(() => {
    if (openChatId) subscribeToChatAsync(dispatch, openChatId);
  }, [openChatId, dispatch]);

  useEffect(() => {
    if (
      openChatData &&
      openChatData.status !== Constants.chatStages.CHAT_OPENED
    ) {
      // dispatch(setOpenChatId(null));
    }
  }, [openChatData]);

  const isImage = (filename) => {
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "tiff", "svg"];
    const ext = filename.split(".").pop().toLowerCase();
    return imageExtensions.includes(ext);
  };

  return (
    <>
      <div>
        {webChat.openChatData && webChat.openChatData.messages
          ? webChat.openChatData.messages.map((message) => {
              if (
                message.messageType ===
                Constants.allowedIncomingMessages.TYPES.TEXT
              ) {
                return (
                  <div
                    className={`chat-message-container ${
                      message.sender === Constants.messageSender.CLIENT
                        ? "outgoing-message"
                        : ""
                    }`}
                  >
                    {message.attachmentIds?.length > 0 && (
                      <div className="image-files">
                        {message.attachmentIds.map((attachment) =>
                          isImage(attachment) ? (
                            <img
                              key={attachment}
                              src={`${Constants.STORAGE_BUCKET_URL}/${Constants.typeOfService.CHATS}/${attachment}`}
                              alt={attachment}
                              className="img-attachment"
                            />
                          ) : (
                            <a
                              key={attachment}
                              href={`${Constants.STORAGE_BUCKET_URL}/${Constants.typeOfService.CHATS}/${attachment}`}
                              download
                              className="file-download shadow"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {attachment}
                            </a>
                          )
                        )}
                      </div>
                    )}
                    <div
                      className="chat-message"
                      dangerouslySetInnerHTML={{
                        __html: parseWhatsAppMessage(message.messageContent),
                      }}
                    />
                  </div>
                );
              } else {
              }
            })
          : ""}
      </div>
    </>
  );
};

export default OpenChat;
